import { index } from "./components/index";
import { Navigation, Pagination, Autoplay, EffectFade, Swiper, Thumbs } from 'swiper'
Swiper.use([Navigation, Pagination, Autoplay, EffectFade, Thumbs]);

index();


$(function () {
	$('.nav__bar').on('click', function () {
		$(this).toggleClass('is_active');
		$(".gnav").toggleClass('is_active');
	})

	//

	$(".thumbnail li a").on('click', function () {
		$(".mainimage span").html($(this).html()).addClass("is_active");
		setTimeout(() => {
			$(".mainimage>img").attr("src", $("img", this).attr("src"));
			$(".mainimage span").html('').removeClass("is_active");
		}, 600);
		return false;
	})

})

$(window).scroll(function () {
	if ($(window).scrollTop() >= 500) {
		$('.pagetop').addClass('is_active');
	} else {
		$('.pagetop').removeClass('is_active');
	}
});

/*
$(window).on('load', function () {
	let headerHeight = $('.header').outerHeight();
	let urlHash = location.hash;
	if (urlHash) {
		let position = $(urlHash).offset().top - headerHeight;
		$('html, body').animate({ scrollTop: position }, 100);
	}
});
*/

$(window).on('load', function () {
	//位置・時間調整
	var adjust = $('.header').outerHeight();
	var time = 500;

	//URLのハッシュ値を取得
	var urlHash = location.hash;
	//ハッシュ値があればページ内スクロール
	if (urlHash) {
		//スクロールを0に戻しておく
		$('body,html').stop().scrollTop(0);
		setTimeout(function () {
			//ロード時の処理を待ち、時間差でスクロール実行
			scrollToAnker(urlHash);
		}, 100);
	}

	//通常のクリック時
	$('a[href^="#"]').on('click', function (event) {
		if (!$(this).hasClass('noanker')) {
			event.preventDefault();

			// ハッシュ値を取得して URI デコードする
			var decodedHash = decodeURI(this.hash);
			// ハッシュの確認
			console.log(decodedHash);
			//リンク先が#か空だったらhtmlに
			var hash = decodedHash == "#" || decodedHash == "" ? 'html' : decodedHash;
			//スクロール実行
			scrollToAnker(hash);
			return false;
		}
	});

	// 関数：スムーススクロール
	// 指定したアンカー(#ID)へアニメーションでスクロール
	function scrollToAnker(hash) {
		console.log(adjust);
		var target = $(hash);
		var position = target.offset().top - adjust;
		$('body,html').stop().animate({ scrollTop: position }, time, 'swing');
	}


	if ($('.studiopage__instagram').length) {
		let list = '';
		$.ajax({
			url: "/instagram.php"
		}).done(function (res) {
			let json = JSON.parse(JSON.stringify(res));
			console.log(json);
			let data = json.media.data;
			data.forEach(element => {
				// console.log(element);
				list +=
					`<div class="item">
							<a href="${element.permalink}" target="_blank" rel="noopener" style="background:url(${element.media_url})"></a>
					</div>`;
			});
			$('.studiopage__instagram .list').html(list);
		});
	}
})
