// const moment = require('moment');
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'
// import { Navigation, Pagination, Autoplay, EffectFade, Swiper } from 'swiper'
// Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);
gsap.registerPlugin(ScrollTrigger);

export function index() {
	$(window).scroll(function () {
		const winScroll = $(this).scrollTop();
		$('.toppage__mainimage .case').css('transform', 'translateY(' + winScroll * -0.5 + 'px)');
	});

	gsap.to('.toppage__message .case', {
		y: -400,
		scrollTrigger: {
			// markers: true,
			trigger: '.toppage__message',
			start: 'top center',
			end: 'bottom top', // アニメーションの終了位置の指定
			scrub: true, // スクロールの進捗とアニメーションの進捗をリンクさせる
		}
	});
	gsap.to('.toppage__service .case', {
		y: -400,
		scrollTrigger: {
			// markers: true,
			trigger: '.toppage__service',
			start: 'top bottom',
			end: 'bottom top', // アニメーションの終了位置の指定
			scrub: true, // スクロールの進捗とアニメーションの進捗をリンクさせる
		}
	});
	gsap.to('.toppage__background1 .case', {
		y: -400,
		scrollTrigger: {
			trigger: '.toppage__background1',
			start: 'top-=100 bottom',
			end: 'bottom top', // アニメーションの終了位置の指定
			scrub: true, // スクロールの進捗とアニメーションの進捗をリンクさせる
		}
	});
	gsap.to('.toppage__background2 .case', {
		y: -400,
		scrollTrigger: {
			trigger: '.toppage__background2',
			start: '20% bottom',
			end: 'bottom top', // アニメーションの終了位置の指定
			scrub: true, // スクロールの進捗とアニメーションの進捗をリンクさせる
		}
	});
	gsap.to('.toppage__news .case', {
		y: -400,
		scrollTrigger: {
			trigger: '.toppage__news',
			start: 'top-=100 bottom',
			end: 'bottom top', // アニメーションの終了位置の指定
			scrub: true, // スクロールの進捗とアニメーションの進捗をリンクさせる
		}
	});
	gsap.to('.toppage__contact .case', {
		y: -400,
		scrollTrigger: {
			trigger: '.toppage__contact',
			start: 'top-=100 bottom',
			end: 'bottom top', // アニメーションの終了位置の指定
			scrub: true, // スクロールの進捗とアニメーションの進捗をリンクさせる
		}
	});
	gsap.to('.titlearea .case', {
		y: -300,
		scrollTrigger: {
			// markers: true,
			trigger: '.titlearea',
			start: 'top-=200 top',
			end: 'bottom top', // アニメーションの終了位置の指定
			scrub: true, // スクロールの進捗とアニメーションの進捗をリンクさせる
		}
	});
	gsap.to('.footer__case .case', {
		y: -400,
		scrollTrigger: {
			trigger: '.footer__case',
			start: 'top-=100 bottom',
			end: 'bottom top', // アニメーションの終了位置の指定
			scrub: true, // スクロールの進捗とアニメーションの進捗をリンクさせる
		}
	});




	// eachTextAnimeにappeartextというクラス名を付ける定義
	function EachTextAnimeControl() {
		$('.eachTextAnime').each(function () {
			var elemPos = $(this).offset().top - 200;
			var scroll = $(window).scrollTop();
			var windowHeight = $(window).height();
			if (scroll >= elemPos - windowHeight) {
				$(this).addClass("appeartext");

			} else {
				$(this).removeClass("appeartext");
			}
		});
		$('.eachPhotoAnime').each(function () {
			var elemPos = $(this).offset().top + 200;
			var scroll = $(window).scrollTop();
			var windowHeight = $(window).height();
			if (scroll >= elemPos - windowHeight) {
				$(this).addClass("appear");

			} else {
				$(this).removeClass("appear");
			}
		});
	}

	// 画面をスクロールをしたら動かしたい場合の記述
	$(window).scroll(function () {
		EachTextAnimeControl();/* アニメーション用の関数を呼ぶ*/
	});// ここまで画面をスクロールをしたら動かしたい場合の記述

	if ($('body').hasClass('inpage')) {
		console.log("a");
		EachTextAnimeControl();
	}
	/*
		$(window).on('load', function () {
			var element = $(".eachTextAnime");
			element.each(function () {
				var text = $(this).text();
				var textbox = "";
				text.split('').forEach(function (t, i) {
					if (t !== " ") {
						if (i < 10) {
							textbox += '<span style="animation-delay:.' + i + 's;">' + t + '</span>';
						} else {
							var n = i / 10;
							textbox += '<span style="animation-delay:' + n + 's;">' + t + '</span>';
						}

					} else {
						textbox += t;
					}
				});
				$(this).html(textbox);
			});

			EachTextAnimeControl();
	});
		*/
}
